import React, { useState, useEffect } from 'react';
import { Container } from '@material-ui/core';

import Brother from './brother';
import { brothers as allBrothers } from './FilteredBros';
import CourseSelect from './CourseSelect';
import StateSelect from './StateSelect';
import YearSelect from './YearSelect';
import './brother.scss';

export const CLEAR_YEAR_FILTER = 1;

/**
 * @param brothers list of brother objects
 * @returns true iff all brothers in brothers list contain filterable fields
 */
function isFilterable(brothers) {
  if (!brothers) return true;
  return brothers.every(bro => bro.year && bro.hometown && bro.major);
}

const BrotherGrid = ({ brothers: propsBrothers, includeRole, showFilters = true, groupByYear = true, sortAlphabetically = true, customGroup = false, showResizableFilter = true }) => {
  const [stateFilter, setStateFilter] = useState('');
  const [courseFilter, setCourseFilter] = useState('');
  const [yearFilter, setYearFilter] = useState(CLEAR_YEAR_FILTER);
  const [visibleBros, setVisibleBros] = useState(propsBrothers ? propsBrothers : allBrothers);
  const [canFilter, setCanFilter] = useState(false);
  const [columns, setColumns] = useState(3);  // Added state to control the number of columns

  useEffect(() => {
    const brothersToFilter = propsBrothers ? propsBrothers : allBrothers;
    setCanFilter(isFilterable(brothersToFilter));
  }, [propsBrothers]);

  useEffect(() => {
    console.log('Filters:', { yearFilter, stateFilter, courseFilter });
    const brothersToFilter = propsBrothers ? propsBrothers : allBrothers;
    const filteredBros = brothersToFilter.filter(bro => {
      const yearMatches =
        yearFilter === CLEAR_YEAR_FILTER ||
        bro.year === yearFilter ||
        (yearFilter === 'Older' && bro.year < 2025 && bro.active && !bro.alumni);
      const stateMatches = stateFilter === '' || bro.hometown.endsWith(stateFilter);
      const courseMatches = courseFilter === '' || bro.major.includes(courseFilter);
      return yearMatches && stateMatches && courseMatches;
    });

    // Conditionally sort alphabetically by name
    if (sortAlphabetically) {
      filteredBros.sort((a, b) => a.name.localeCompare(b.name));
    }

    setVisibleBros(filteredBros);
  }, [yearFilter, stateFilter, courseFilter, propsBrothers, sortAlphabetically]);

  const resetFilters = () => {
    setYearFilter(CLEAR_YEAR_FILTER);
    setCourseFilter('');
    setStateFilter('');
  };

  // Group brothers by year, including "Older" group, and enforce specific order
  const groupByYearFunc = bros => {
    if (customGroup) {
      return { all: bros };
    }
    const groups = {
      Older: [],
      2025: [],
      2026: [],
      2027: [],
      2028: []
    };

    bros.forEach(bro => {
      const groupKey = bro.year < 2025 && bro.active && !bro.alumni ? 'Older' : bro.year;
      if (groups[groupKey]) {
        groups[groupKey].push(bro);
      }
    });

    return groups;
  };

  const groupedBros = groupByYear ? groupByYearFunc(visibleBros) : { all: visibleBros };

  const groupOrder = ['Older', '2025', '2026', '2027', '2028']; // Explicit order

  return (
    <div>
      <Container>
        {showFilters && (
          <>
            <div className="buttons">
              <button onClick={() => resetFilters()}>Clear Filters</button>
              <div className="year-filters">
                <YearSelect
                  yearFilter={yearFilter}
                  yearList={[2028, 2027, 2026, 2025]}
                  setYearFilter={setYearFilter}
                />
              </div>
            </div>
            <div className="filters">
              <StateSelect
                stateFilter={stateFilter}
                stateSet={new Set(visibleBros.map(bro => bro.hometown.split(',')[1]))}
                setStateFilter={setStateFilter}
                placeholder={stateFilter === '' ? 'All States' : stateFilter}
              />
              <CourseSelect
                courseFilter={courseFilter}
                courseSet={new Set(visibleBros.map(bro => bro.major).flat())}
                setCourseFilter={setCourseFilter}
                placeholder={courseFilter === '' ? 'All Courses' : courseFilter}
              />
            </div>
          </>
        )}
        {showResizableFilter && (
          <div className="slider-container">
            <label htmlFor="columns-slider">Brothers per row: {columns}</label>
            <input
              type="range"
              id="columns-slider"
              min="1"
              max="5"
              value={columns}
              onChange={(e) => setColumns(e.target.value)}
              className="slider"
            />
          </div>
        )}
      </Container>
      <div style={{ marginTop: '8px', display: 'flex', justifyContent: 'flex-end', margin: '64px 0 16px 0' }}></div>
      <div>
        {customGroup
          ? Object.keys(groupedBros).map(group =>
              groupedBros[group]?.length > 0 && (
                <div key={group}>
                  <div className={'cards'} style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
                    {groupedBros[group].map((bro, index) => (
                      <Brother key={`${index}`} brother={bro} includeRole={includeRole} />
                    ))}
                  </div>
                </div>
              )
            )
          : groupOrder.map(group =>
              groupedBros[group]?.length > 0 && (
                <div key={group}>
                  <h2>{group === 'Older' ? 'Older Brothers' : `Class of ${group}`}</h2>
                  <div className={'cards'} style={{ gridTemplateColumns: `repeat(${columns}, 1fr)` }}>
                    {groupedBros[group].map((bro, index) => (
                      <Brother key={`${index}`} brother={bro} includeRole={includeRole} />
                    ))}
                  </div>
                  <div style={{ margin: '50px 0' }}></div> {/* Add space between year groups */}
                </div>
              )
            )}
      </div>
    </div>
  );
};

export default BrotherGrid;
